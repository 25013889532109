<template>
    <div class="body">
        <div v-for="(item, index) in data.items" :key="index">
            <div class="description" v-if="item.description">
                {{ item.description }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
};
</script>

<style lang="scss" scoped>
.body {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.description {
    font-size: 16px;
    color: #666;
    line-height: 32px;
    margin-bottom: 95px;
    margin-top: 30px;
}

</style>
