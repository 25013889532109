<template>
    <div class="body">
        <div class="content">
            <p style="font-weight: bold;font-size: 35px;line-height: 38px;text-align: center;color:#0d4b81;">{{
                data.items[0].title }}</p>
            <div class="text">{{ data.items[0].description }}</div>
            <img :src="data.items[0].cover" alt="">
        </div>
    </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  created() {
    console.log('safe', this.data);
  }
};
</script>

<style lang="scss" scoped>
.body {
    width: 96%;
    margin: 0 auto;
    max-width: 918px;
    padding-top: 50px;
    padding-bottom: 50px;

}

@media screen and (min-width: 700px) {
    .content {
        width: 100%;
        margin: 0 0 0 auto;
    }
}

.text{
    margin-top: 30px;
    font-size: 18px;
    white-space: pre-line;
    padding-bottom: 30px;
    line-height: 39px;
}
img{
    width: 100%;
    padding-bottom: 30px;
}

@media screen and (max-width: 700px) {
    .body {
        margin: 0 auto;
        padding-top: 50px;
    }

    .content {
        width: 100%;
    }

    .right {
        margin: 0 auto;
        width: 94%;
        // margin-top: 10px;
        margin-bottom: 30px;
        padding-top: 30px;
    }
    .right p{
        text-align: center;
    }

    .left {
        width: 94%;
        margin: 0 auto;
    }

}
</style>
