<template>
    <div class="body">
        <div v-for="(item, index) in data.items" :key="index" :class="index % 2 === 0 ? 'body-left' : 'body-right'">
            <div class="image" v-if="index % 2 === 0">
                <img :src="item.cover" style="width: 100%" />
            </div>
            <div class="text" v-if="screenwidth === 'large'">
                <div class="title" v-if="item.title">
                    {{ item.description }}
                </div>
            </div>
            <div class="image" v-if="index % 2 === 1">
                <img :src="item.cover" style="width: 100%" />
            </div>
            <div class="text" v-if="screenwidth === 'small'">
                <div class="title" v-if="item.title">
                    {{ item.description }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    data() {
        return {
            screenwidth: ''
        }
    },
    created() {
        if (document.documentElement.clientWidth > 700) {
            this.screenwidth = "large";
        } else {
            this.screenwidth = "small";
        }
        window.addEventListener('resize', this.handleResize);
        console.log(this.data);
    },
    // mounted() {
    //     window.onresize = () => {
    //         return (() => {
    //             if (document.documentElement.clientWidth > 700) {
    //                 this.screenwidth = "large";
    //             } else {
    //                 this.screenwidth = "small";
    //             }
    //         })();
    //     };
    // },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize(event) {
            this.fullWidth = document.documentElement.clientWidth;
            if (this.fullWidth < 701) {
                this.screenwidth = "small";
            } else {
                this.screenwidth = "large";
            }
        },

    }
};
</script>
<style lang="scss" scoped>
.body {
    height: auto;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    margin-top: 60px;

    .body-left {
        margin-top: -30px;
        margin-right: -30px;
        display: flex;
        position: relative;
        overflow: hidden;
    }

    .body-right {
        margin-top: -30px;
        display: flex;
        position: relative;
        overflow: hidden;
    }

    .title {
        font-size: 30px;
        line-height: 45px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        overflow: hidden;
        color: rgba(35, 24, 21, 0.6);
    }

    .text {
        height: 400px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        white-space: pre-line;
    }

    .image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 400px;
        float: right;
    }

    .image img {
        max-height: 100%;
        width: 100%;
        border-radius: 15px;
    }
}

@media screen and (max-width: 700px) {
    .body {

        .body-left,
        .body-right {
            margin: 0 auto;
            width: 95%;
            display: block;
        }

        .text {
            height: 20vw;
        }

        .image {
            height: 70vw;
        }

        .title {
            font-size: 2.5vw;
            line-height: 30px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }
    }
}
</style>
