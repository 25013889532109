<template>
    <div class="body" >
        <div class="image"></div>
        <div v-for="(item, index) in data.items" :key="index">
            <div :class="index % 2 === 0 ? 'left' : 'right'">
                <div class="content">
                    <div class="title">{{ item.title }}</div>
                    <div class="description">{{ item.description }}</div>
                </div>
                <div class="icon"><img src="../../assets/images/official/icon1.png"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
};
</script>

<style lang="scss" scoped>
.clear {
    clear: both;
}
.body {
    width: 100%;
    max-width: 1200px;
    margin: 58px auto 0 auto;
    position: relative;
    overflow: hidden;
    padding-bottom: 50px;
}

.image {
    width: 22px;
    height: 20000px;
    left: 50%;
    margin-left: -11px;
    background: url(../../assets/images/official/timeline.png) center no-repeat;
    background-size: 100% 100%;
    position: absolute;
}

.left {
    width: 51.6%;
    float: left;
    text-align: right;
    margin-top: 26px;

    .content {
        width: 87%;
        float: left;
    }

    .title {
        font-size: 28px;
        line-height: 36px;
        color: #6fa3f3;
        font-weight: 100;
        white-space: pre-wrap;
    }

    .description {
        font-size: 16px;
        color: #666;
    }

    .icon {
        width: 6.5%;
        float: right;
        margin-top: 24px;
    }

    .icon img {
        width: 100%;
    }
}

.right {
    width: 51.6%;
    float: right;
    text-align: left;
    margin-top: 26px;

    .content {
        width: 87%;
        float: right;
    }

    .title {
        font-size: 28px;
        line-height: 36px;
        color: #6fa3f3;
        font-weight: 100;
        white-space: pre-wrap;
    }

    .description {
        font-size: 16px;
        color: #666;
    }

    .icon {
        width: 6.5%;
        float: left;
        margin-top: 24px;
    }

    .icon img {
        width: 100%;
    }
}
</style>
