<template>
    <div class="body">
        <div v-for="(item, index) in data.items" :key="index">
            <a @click="toUrl(item.router)">
                <div :class="index % 2 === 0 ? 'left' : 'right'">
                    <div class="icon" :style="{ background: item.color }">
                        <img v-if="item.icon" :src="item.icon" />
                    </div>
                    <div class="content">
                        <div class="cover">
                            <img v-if="item.cover" :src="item.cover" />
                        </div>
                        <p>
                            {{ item.description }}
                        </p>
                    </div>
                </div>
            </a>
        </div>
        <div class="clear"></div>
    </div>
</template>
<script>
export default {
    props: {
        data: Object,
    },
    data() {
        return {
            colorList: ["#3c7bbf", "#588d38", "#da712b", "#a54a66", "#40968a"],
        };
    },
    created() {
        console.log(this.data);
        this.data.items.forEach((item, index) => {
            item.color = this.colorList[index % 5];
        });
    },
    methods: {
        toUrl(url) {
            if (url.includes('http')) {
                window.open(url);
            } else {
                this.$router.push(url);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.clear {
    clear: both;
}

.body a {
    cursor: pointer;
    color: #2b292f;
    text-decoration: none;
    outline: none;
}

.body {
    margin-top: 120px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 10px;
}

.icon {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: Center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.icon img {
    height: 70%;
}

.content {
    background: #f4f7f9;
    padding-bottom: 40px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.content img {
    width: 100%;
}

.content p {
    height: 80px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    font-size: 16px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.6);
    margin: 0 9%;
    margin-top: 35px;
}

.left {
    width: 48%;
    margin-bottom: 50px;
    float: left;
}

.right {
    width: 48%;
    margin-bottom: 50px;
    float: right;
}

.cover {
    padding-top: 30px;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    width: 94%;
    aspect-ratio: 8 / 5;
}

.cover img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 700px) {
    .body {
        padding: 0 20px;
    }

    .icon {
        height: 20vw;
    }

    .left,
    .right {
        width: 100%;
        float: none;
        margin: 0 auto;
        margin-bottom: 60px;
    }

    .content p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-size: 22px;
    }
}
</style>
