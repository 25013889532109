<template>
    <div class="body">
        <div class="content">
            <div class="image2"><img :src="activeName ? data.items[activeName].cover : data.items[0].cover" alt=""></div>
            <div class="box">
                <el-collapse
                v-model="activeName"
                accordion
                >
                    <el-collapse-item
                        v-for="(item, index) in data.items"
                        :key="index"
                        :name="index"
                        >
                        <template slot="title">
                            <div class="custom-title" :class="{ 'active': activeName === index }">
                                <span class="dot" :class="{ 'filled': activeName === index }"></span>
                                <span>{{ item.title }}</span>
                                <!-- <p class="first-item">{{ item.title }}</p> -->
                            </div>
                        </template>
                        <div class="description">{{ item.description }}</div>
                    </el-collapse-item>
                </el-collapse>
                <div class="button"><a href="/download">前往下载</a></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  data() {
    return {
      activeName: 0 // 用于追踪当前激活的手风琴项
    };
  },
  created() {
    console.log('111', this.data);
    console.log('activeName', this.activeName);
  },
  methods: {
    toUrl(url) {

    }
  }
};
</script>

<style lang="scss" scoped>
.body {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 50px;
}

@media screen and (min-width: 700px) {
    .content {
        display: flex;
        width: 100%;
        margin: 0 0 0 auto;
        // justify-content: space-between;
        justify-content: space-between;
        flex-direction:row-reverse;
        align-items: flex-start;
    }
}

.box{
    // width: 500px;
    width: 40%;
    margin-right: 60px;
}
.custom-title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  //   display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 1;
    // overflow: hidden;
  font-family: SourceHanSansCN, SourceHanSansCN;
  cursor: pointer;
}
.custom-title .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #2B6DC5;
  margin-right: 20px;
}

.custom-title .dot.filled {
  background-color: #2B6DC5;
}

.custom-title{
    width: 100%;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 1;
    // overflow: hidden;
}
.custom-title.active {
  color: #2B6DC5;
}

.el-collapse-item__arrow {
  display: none;
}
.el-icon-arrow-right{
    display: none;
}

.image2{
    // width: 750px
    width: 49%;
    // margin-left: 50px;
    // margin-right: 200px;
    // border: 1px solid;

}
.image2 img {
    width: 100%;
    margin-top: 20px;
    border-radius: 24px;
    box-shadow: 0px 2px 4px 3px rgba(220,220,220,0.5);
    display: block;
}
.description {
    // padding-bottom: 12px;
    font-size: 14px;
    padding-left: 30px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    line-height: 26px;
    white-space: pre-line;
}
.box{
    position: relative;
}
.button{
    position: absolute;
    bottom: -42px;
    font-size: 20px;
    width: 162px;
    height: 41px;
    text-align: center;
    border-radius: 24px;
    border: 1px solid #2B6DC5;
    font-size: 16px;
    font-weight: 400;
    color: #2B6DC5;
    line-height: 40px;
}

@media screen and (max-width: 700px) {
    .body {
        margin: 0 auto;
        padding-top: 50px;
    }

    .content {
        width: 100%;
    }

    .box{
        width: 100%;
    }
    .image2 {
        margin: 0 auto;
        width: 90%;
        height: 25vh;
        margin-bottom:20px;
    }

    .image2 img {
        display: block;
    }

    .description {
        width: 100%;
        height: 10vh;
        font-size: 3vw;
        line-height: 3vw;
        white-space: pre-line;
    }
}
</style>
