<template>
    <div class="body">
        <div class="content">
            <el-carousel type="card" :height="screenwidth" :autoplay="false" arrow="never" indicator-position="none"
                style="margin: 0 auto;width: 78%;height: 100%" ref="cardShow">
                <el-carousel-item v-for="(item, index) in data.items" :key="index">
                    <img :src="item.cover" width="100%" height="100%" />
                </el-carousel-item>
            </el-carousel>
            <div class="next" @click="arrowClick('next')"></div>
            <div class="prev" @click="arrowClick('prev')"></div>
        </div>
        <div class="text">
            <div v-for="(item, index) in data.items" :key="index">
                {{ item.description }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        if (document.documentElement.clientWidth > 700) {
            this.screenwidth =
                300 + "px";
        } else {
            this.screenwidth =
                130 + "px";
        }
        console.log(this.data);
    },
    data() {
        return {
            screenwidth: '700px'
        };
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                if (document.documentElement.clientWidth > 700) {
                    this.screenwidth =
                        300 + "px";
                } else {
                    this.screenwidth =
                        130 + "px";
                }
            })();
        };
    },
    methods: {
        arrowClick(val) {
            if (val === "next") {
                this.$refs.cardShow.next();
            } else {
                this.$refs.cardShow.prev();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.body {
    width: 100%;
    max-width: 1200px;
    margin: 50px auto 0 auto;
    display: block;
    white-space: pre-line;
}

.text {
    width: 70%;
    margin: 60px auto 0 auto;
    text-align: center;
    color: #3e3a39;
    line-height: 30px;
    font-size: 16px;
}

.content {
    text-align: center;
    position: relative;

    .next {
        background: url(https://www.tongyuan.cc/templets/seowhy/new/images/29.png);
        background-size: 100% 100%;
        right: 0;
        left: auto;
        top: 50%;
        position: absolute;
        width: 44px;
        height: 79px;
        margin-top: -50px;
        cursor: pointer;
    }

    .prev {
        left: 0;
        right: auto;
        top: 50%;
        position: absolute;
        background: url(https://www.tongyuan.cc/templets/seowhy/new/images/28.png);
        background-size: 100% 100%;
        width: 44px;
        height: 79px;
        margin-top: -50px;
        cursor: pointer;
    }
}

@media screen and (max-width: 700px) {
    .text {
        width: 90%;
        margin: 30px auto 0 auto;
        text-align: left;
        color: #3e3a39;
        line-height: 25px;
        font-size: 14px;
    }

    .content {
        .next {
            top: 60%;
            width: 35px;
            height: 60px;
        }

        .prev {
            top: 60%;
            width: 35px;
            height: 60px;
        }
    }
}
</style>
