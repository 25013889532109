<template>
    <div class="body">
        <div v-for="(item, index) in data.items" :key="index">
            <div class="title" v-if="item.title">
                {{ item.title }}
            </div>
            <div class="image" v-if="item.cover">
                <img :src="item.cover" />
            </div>
            <div class="description" v-if="item.description">
                {{ item.description }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  created() {
    console.log(this.data);
  }
};
</script>

<style lang="scss" scoped>
.body {
    width: 96%;
    margin: 0 auto;
    padding-top: 50px;
    max-width: 918px;
    white-space: pre-line;
}
.image img {
    width: 100%;
    padding-bottom: 30px;
}
.title {
    font-size: 20px;
    line-height: 35px;
    font-weight: bold;
}

.description {
    padding-bottom: 30px;
    font-size: 17px;
    line-height: 39px;
}

</style>
