<template>
    <div class="body">
        <div v-for="(item, index) in data.items" :key="index">
            <div v-if="index === 0" class="large" :style="{
                backgroundImage: 'url(' + item.cover + ')',
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
            }">
                <div class="large_tp">
                    <img :src="item.icon" />
                </div>
                <div class="large_wz">
                    <p>
                        {{ item.description }}
                    </p>
                    <div class="large_an">
                        <a @click="toUrl(item.buttons[0].router)" title="MWORKS" v-if="item.buttons[0]">
                            {{ item.buttons[0].name }}
                        </a>
                    </div>
                </div>
            </div>
            <div v-else-if="index !== 0 && index !== (data.items.length - 1)"
                :class="(index + 1) % 3 === 0 ? 'small02' : 'small01'" :style="{
                    backgroundImage: 'url(' + item.cover + ')',
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                }">
                <div class="small01_tp">
                    <img :src="item.icon" />
                </div>
                <div class="small01_wz">
                    {{ item.description }}
                </div>
                <div :class="item.buttons.length === 1 ? 'button' : 'buttons'">
                    <a class="item1" @click="toUrl(item.buttons[0].router)" v-if="item.buttons[0]">
                        {{ item.buttons[0].name.length > 4 ? item.buttons[0].name.slice(0, 3) + '...' : item.buttons[0].name
                        }}
                    </a>
                    <a class="item2" @click="toUrl(item.buttons[1].router)" v-if="item.buttons[1]">
                        {{ item.buttons[1].name.length > 4 ? item.buttons[1].name.slice(0, 3) + '...' : item.buttons[1].name
                        }}
                    </a>
                </div>
            </div>
            <div v-else-if="index === (data.items.length - 1) && (data.items.length) % 3 === 0" class="normal" :style="{
                backgroundImage: 'url(' + item.cover + ')',
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
            }">
                <div class="normal_tp"><img :src="item.icon" /></div>
                <div class="normal_wz">{{ item.description }}</div>
                <div class="normal_an">
                    <a @click="toUrl(item.buttons[0].router)" class="normal_an01" v-if="item.buttons[0]">
                        {{ item.buttons[0].name.length > 4 ? item.buttons[0].name.slice(0, 3) + '...' : item.buttons[0].name
                        }}
                    </a>
                    <a @click="toUrl(item.buttons[1].router)" class="normal_an02" v-if="item.buttons[1]">
                        {{ item.buttons[1].name.length > 4 ? item.buttons[1].name.slice(0, 3) + '...' : item.buttons[1].name
                        }}
                    </a>
                </div>
            </div>
            <div v-else-if="index === (data.items.length - 1) && (data.items.length) % 3 !== 0"
                :class="(index + 1) % 3 === 0 ? 'small02' : 'small01'" :style="{
                    backgroundImage: 'url(' + item.cover + ')',
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                }">
                <div class="small01_tp">
                    <img :src="item.icon" />
                </div>
                <div class="small01_wz">
                    {{ item.description }}
                </div>
                <div :class="item.buttons.length === 1 ? 'button' : 'buttons'">
                    <a class="item1" @click="toUrl(item.buttons[0].router)" v-if="item.buttons[0]">
                        {{ item.buttons[0].name.length > 4 ? item.buttons[0].name.slice(0, 3) + '...' : item.buttons[0].name
                        }}
                    </a>
                    <a class="item2" @click="toUrl(item.buttons[1].router)" v-if="item.buttons[1]">
                        {{ item.buttons[1].name.length > 4 ? item.buttons[1].name.slice(0, 3) + '...' : item.buttons[1].name
                        }}
                    </a>
                </div>
            </div>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
    methods: {
        toUrl(url) {
            if (url.includes('http')) {
                window.open(url);
            } else {
                this.$router.push(url);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.body {
    display: block;
    width: 96%;
    margin: 0 auto;
    max-width: 1250px;
    color: #dde0e2;
}

.large {
    height: 410px;
    margin-top: 60px;
    padding: 110px 8.8% 0 8.8%;
    border-radius: 10px;
}

.large_tp {
    width: 15.67%;
    float: left;
}

.large_tp img {
    width: 100%;
}

.large_wz {
    width: 68.67%;
    float: right;
}

.large_wz p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    font-size: 20px;
    color: #dedede;
    margin-bottom: 50px;
    line-height: 28px;
}

.small01 {
    width: 31.66%;
    height: 450px;
    float: left;
    margin-top: 30px;
    padding-top: 56px;
    border-radius: 10px;
}

.small02 {
    width: 31.66%;
    height: 450px;
    float: left;
    margin: 30px 2.5% 0 2.5%;
    padding-top: 56px;
    border-radius: 10px;
}

.small01_tp {
    width: 43.68%;
    height: 100px;
    margin: 0 auto;
    text-align: center;
}

.small01_tp img {
    max-width: 100%;
    max-height: 100%;
}

.small01_wz {
    height: 80px;
    width: 68.42%;
    margin: 24px auto 0 auto;
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.normal {
    width: 65.83%;
    height: 450px;
    float: right;
    margin: 30px 0 0 0;
    padding-top: 56px;
    border-radius: 10px;
}

.normal_tp {
    width: 17.7%;
    height: 100px;
    margin: 0 auto;
    text-align: center;
}

.normal_tp img {
    max-width: 100%;
    max-height: 100%;
}

.normal_wz {
    height: 80px;
    width: 68.42%;
    margin: 24px auto 0 auto;
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

@media screen and (min-width: 1100px) {

    .large_wz a {
        cursor: pointer;
        padding: 10px 30px;
        line-height: 50px;
        color: #fff;
        background: #467ed1;
        margin-top: 80px;
        text-align: center;
        border-radius: 28px;
        font-size: 18px;
    }

    .large_wz a:hover {
        background: #2761b7;
    }

    .buttons {
        width: 83.68%;
        margin: 50px auto 0 auto;

        .item1 {
            float: left;
            height: 100%;
            cursor: pointer;
            padding: 10px 30px;
            color: #fff;
            border: 1px solid #fff;
            text-align: center;
            border-radius: 28px;
            font-size: 18px;

            &:hover {
                background: #fff;
                color: #467ed1;
            }
        }

        .item2 {
            float: right;
            height: 100%;
            cursor: pointer;
            padding: 10px 30px;
            color: #fff;
            background: #467ed1;
            text-align: center;
            border-radius: 28px;
            font-size: 18px;

            &:hover {
                background: #2761b7;
            }
        }
    }

    .button {
        width: 83.68%;
        margin: 60px auto 0 auto;
        text-align: center;

        .item1 {
            height: 100%;
            cursor: pointer;
            padding: 10px 30px;
            color: #fff;
            border: 1px solid #fff;
            text-align: center;
            border-radius: 28px;
            font-size: 18px;

            &:hover {
                background: #fff;
                color: #467ed1;
            }
        }
    }

    .normal_an {
        width: 83.68%;
        margin: 50px auto 0 auto;

        .normal_an01 {
            cursor: pointer;
            padding: 10px 30px;
            float: left;
            margin: 0 auto;
            display: block;
            color: #fff;
            border: 1px solid #fff;
            text-align: center;
            border-radius: 28px;
            font-size: 18px;

            &:hover {
                background: #fff;
                color: #467ed1;
            }
        }

        .normal_an02 {
            cursor: pointer;
            padding: 10px 30px;
            float: right;
            display: block;
            color: #fff;
            background: #467ed1;
            text-align: center;
            border-radius: 28px;
            font-size: 18px;

            &:hover {
                background: #2761b7;
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .large_wz a {
        cursor: pointer;
        padding: 0.6vw 2vw;
        line-height: 50px;
        color: #fff;
        background: #467ed1;
        margin-top: 80px;
        text-align: center;
        border-radius: 28px;
        font-size: 10px;
    }

    .large_wz a:hover {
        background: #2761b7;
    }

    .buttons {
        width: 83.68%;
        margin: 60px auto 0 auto;

        .item1 {
            float: left;
            height: 100%;
            cursor: pointer;
            padding: 0.6vw 2vw;
            color: #fff;
            border: 1px solid #fff;
            text-align: center;
            border-radius: 28px;
            font-size: 10px;

            &:hover {
                background: #fff;
                color: #467ed1;
            }
        }

        .item2 {
            float: right;
            height: 100%;
            cursor: pointer;
            padding: 0.6vw 2vw;
            color: #fff;
            background: #467ed1;
            text-align: center;
            border-radius: 28px;
            font-size: 10px;

            &:hover {
                background: #2761b7;
            }
        }
    }

    .button {
        width: 83.68%;
        margin: 60px auto 0 auto;
        text-align: center;

        .item1 {
            height: 100%;
            cursor: pointer;
            padding: 0.6vw 2vw;
            color: #fff;
            border: 1px solid #fff;
            text-align: center;
            border-radius: 28px;
            font-size: 10px;

            &:hover {
                background: #fff;
                color: #467ed1;
            }
        }
    }

    .normal_an {
        width: 90%;
        margin: 55px auto 0 auto;
    }

    .normal_an01 {
        cursor: pointer;
        padding: 0.6vw 2vw;
        float: left;
        margin: 0 auto;
        display: block;
        color: #fff;
        border: 1px solid #fff;
        text-align: center;
        border-radius: 28px;
        font-size: 10px;

        &:hover {
            background: #fff;
            color: #467ed1;
        }
    }

    .normal_an02 {
        cursor: pointer;
        padding: 0.6vw 2vw;
        float: right;
        display: block;
        color: #fff;
        background: #467ed1;
        text-align: center;
        border-radius: 28px;
        font-size: 10px;

        &:hover {
            background: #2761b7;
        }
    }
}

@media screen and (max-width: 700px) {
    .body {
        width: 96%;
        margin: 0 auto;
        color: #dde0e2;
    }

    .large,
    .small01,
    .small02,
    .normal {
        height: auto;
        width: 100%;
        padding: 5vh 0;
        margin: 2vh 0 0 0;
        border-radius: 10px;
        background-size: 100% 100%;
        float: none;
    }

    .large_tp {
        float: none;
        width: 20%;
        height: 20vw;
        margin: 1vw auto;
        text-align: center;
    }

    .small01_tp,
    .normal_tp {
        width: 35%;
        height: 20vw;
        margin: 1vw auto;
        text-align: center;
    }

    .large_tp img,
    .small01_tp img,
    .normal_tp img {
        max-width: 100%;
        height: 100%;
        border: none;
    }

    .large_wz,
    .small01_wz,
    .normal_wz {
        font-size: 3.5vw;
        float: none;
        width: 90%;
        margin: 20px auto;
        text-align: center;
    }

    .large_wz p {
        font-size: 3.5vw;
        color: #dedede;
    }

    .large_an,
    .normal_an,
    .button,
    .buttons {
        height: 50px;
        width: 60%;
        margin: .2rem auto 0 auto;
    }

    .buttons .item1,
    .buttons .item2,
    .button .item1,
    .normal_an02,
    .normal_an01,
    .large_an a {
        width: 30%;
        line-height: 18px;
        display: block;
        color: #fff;
        margin: 2vh auto 0 auto;
        text-align: center;
        border-radius: 28px;
        padding: 0.6vw 2vw;
        font-size: 2.5vw;
        height: auto;
    }

    .large_an a {
        width: 35%;
        line-height: 2vh;
        display: block;
        color: #fff;
        margin: 2vh auto 0 auto;
        text-align: center;
        border-radius: 28px;
        font-size: 2.5vw;
        padding: 1vw 2vw;
        background: #467ed1;

        &:hover {
            background: #2761b7;
        }
    }

    .buttons .item2,

    .normal_an02 {
        float: right;
        padding: 1vw 2vw;
        background: #467ed1;

        &:hover {
            background: #2761b7;
        }
    }

    .button .item1 {
        padding: 1vw 2vw;
        border: 1px solid #fff;

        &:hover {
            background: #fff;
            color: #467ed1;
        }
    }

    .buttons .item1,
    .normal_an01 {
        float: left;
        padding: 1vw 2vw;
        border: 1px solid #fff;

        &:hover {
            background: #fff;
            color: #467ed1;
        }
    }
}
</style>
