<template>
    <div class="body">
        <div class="content">
            <el-carousel :height="screenwidth" :autoplay="false" arrow="never" indicator-position="outside"
                style="margin: 0 auto;width: 90%;height: 100%" ref="cardShow">
                <el-carousel-item v-for="(item, index) in data.items" :key="index">
                    <el-card style="height: 100%">
                        <div class="left">
                            <div class="text">
                                <div class="icon"><img :src="item.icon"></div>
                                <div class="title">{{ item.title }}</div>
                                <div class="description">{{ item.description }}</div>
                            </div>
                        </div>
                        <div class="right"><img v-if="item.cover" :src="item.cover" draggable="false">
                        </div>
                    </el-card>
                </el-carousel-item>
            </el-carousel>
            <div class="next" @click="arrowClick('next')"></div>
            <div class="prev" @click="arrowClick('prev')"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        if (document.documentElement.clientWidth > 700) {
            this.screenwidth =
                400 + "px";
        } else {
            this.screenwidth =
                "90vw";
        }
        console.log(this.data);
    },
    data() {
        return {
            screenwidth: '700px'
        };
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                if (document.documentElement.clientWidth > 700) {
                    this.screenwidth =
                        400 + "px";
                } else {
                    this.screenwidth =
                        "90vw";
                }
            })();
        };
    },
    methods: {
        arrowClick(val) {
            if (val === "next") {
                this.$refs.cardShow.next();
            } else {
                this.$refs.cardShow.prev();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.body {
    width: 100%;
    max-width: 1300px;
    margin: 50px auto;
}

.content {
    position: relative;
    display: block;

    .next {
        background: url(https://www.tongyuan.cc/templets/seowhy/new/images/29.png);
        background-size: 100% 100%;
        right: 0;
        left: auto;
        top: 30%;
        position: absolute;
        width: 44px;
        height: 79px;
        cursor: pointer;
    }

    .prev {
        left: 0;
        right: auto;
        top: 30%;
        position: absolute;
        background: url(https://www.tongyuan.cc/templets/seowhy/new/images/28.png);
        background-size: 100% 100%;
        width: 44px;
        height: 79px;
        cursor: pointer;
    }

    .left {
        width: 43.33%;
        float: left;
    }

    .right {
        width: 56.67%;
        float: right;
    }

    .right img {
        width: 100%;
        height: 400px;
    }

    .text {
        width: 80%;
        margin: 72px auto 0 auto;
    }

    .icon {
        height: 50px;
        width: 50px;
    }

    .icon img {
        max-height: 100%;
        max-width: 100%;
    }

    .title {
        font-size: 28px;
        margin-top: 20px;
        color: #666;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }

    .description {
        font-size: 16px;
        margin-top: 28px;
        line-height: 30px;
        color: #999;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
    }

    /deep/ .el-card__body {
        padding: 0;
    }

    /deep/ .el-carousel__indicators {
        margin-top: 50px;
    }

    /deep/ .el-carousel__indicators button {
        background-color: #5096ea;
        height: 6px;
        width: 52px;
    }
}

@media screen and (max-width: 700px) {
    .content {
        width: 100%;

        .next,
        .prev {
            display: none;
        }

        .title {
            height: 7vw;
            font-size: 4vw;
            margin: 2vw 0;
        }

        .description {
            line-height: 4vw;
            height: 12vw;
            font-size: 3vw;
            margin: 2vw 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }

        .text {
            margin: 6vw auto 0 auto;
        }

        .icon {
            height: 8vw;
            width: 8vw;
        }

        .icon img {
            max-height: 100%;
            max-width: 100%;
        }

        .right,
        .left {
            width: 100%;
            float: none;
        }

        .right img {
            width: 88vw;
            height: 50vw;
        }
    }
}</style>
