<template>
    <div class="body">
        <div v-for="(item, index) in data.items" :key="index">
            <div class="item">
                <div class="image" v-if="index % 2 === 0" :style="{
                    backgroundImage: 'url(' + item.cover + ')',
                    backgroundSize: '100% 100%',
                }">
                </div>
                <div class="text" v-if="screenwidth === 'large'">
                    <div class="title">{{ item.title }}</div>
                    <div class="description">{{ item.description }}</div>
                    <div class="more">
                        <a @click="toUrl(item.router)">了解更多</a>
                    </div>
                </div>
                <div class="image" v-if="index % 2 === 1" :style="{
                    backgroundImage: 'url(' + item.cover + ')',
                    backgroundSize: '100% 100%',
                }">
                    <img :src="item.icon" />
                </div>
                <div class="text" v-if="screenwidth === 'small'">
                    <div class="title">{{ item.title }}</div>
                    <div class="description">{{ item.description }}</div>
                    <div class="more">
                        <a @click="toUrl(item.router)">了解更多</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    data() {
        return {
            screenwidth: ''
        }
    },
    created() {
        if (document.documentElement.clientWidth > 700) {
            this.screenwidth = "large";
        } else {
            this.screenwidth = "small";
        }
        window.addEventListener('resize', this.handleResize);
        console.log(this.data);
    },
    // mounted() {
    //     window.onresize = () => {
    //         return (() => {
    //             if (document.documentElement.clientWidth > 700) {
    //                 this.screenwidth = "large";
    //             } else {
    //                 this.screenwidth = "small";
    //             }
    //         })();
    //     };
    // },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize(event) {
            this.fullWidth = document.documentElement.clientWidth;
            if (this.fullWidth < 701) {
                this.screenwidth = "small";
            } else {
                this.screenwidth = "large";
            }
        },
        toUrl(url) {
            if (url.includes('http')) {
                window.open(url);
            } else {
                this.$router.push(url);
            }
        },

    }
};
</script>

<style lang="scss" scoped>
.body {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 124px 0;

    .item {
        height: 450px;
        margin: 0 auto;
        display: flex;
        background-color: white;

        .image {
            width: 85%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .text {
            width: 40%;
            padding-top: 85px;
            position: relative;

            .title {
                font-size: 34px;
                color: #585858;
                text-align: center;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }

            .description {
                width: 70%;
                margin: 56px auto 0 auto;
                color: #676767;
                font-size: 16px;
                line-height: 24px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
            }

            .more {
                width: 100%;
                height: 108px;
                position: absolute;
                background: #467ed1;
                bottom: 0;
                justify-content: center;
                align-items: center;
                display: flex;
            }

            .more a {
                cursor: pointer;
                display: block;
                width: 35%;
                height: 40px;
                background: #fff;
                font-size: 16px;
                color: #467ed1;
                text-align: center;
                line-height: 40px;
                border-radius: 24px;
            }

            .more a:hover {
                background: #467ed1;
                color: #fff;
                border: 1px solid #fff;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .body {
        width: 95%;

        .item {
            width: 100%;
            height: auto;
            display: block;
            margin-bottom: 15px;

            .image {
                height: 50vw;
                width: 100%;
            }

            .text {
                width: 100%;
                padding-top: 3vw;
                height: 100%;
                height: 50vw;

                .title {
                    font-size: 5vw;
                }

                .description {
                    width: 70%;
                    margin: 3vw auto 0 auto;
                    font-size: 3vw;
                    line-height: 4vw;
                }

                .more {
                    width: 100%;
                    height: 20vw;
                }

                .more a {
                    font-size: 2.5vw;
                    height: 7vw;
                    line-height: 7vw;
                }
            }
        }
    }
}
</style>
