<template>
    <div class="body">
        <!-- <div class="title">
            <span>我们为您准备了以下课程，帮助您熟练使用MWORKS平台</span>
        </div> -->
        <div class="content">
            <ul>
                <li v-for="(item, index) in data.items" :key="index">
                    <div class="card">
                        <div class="icon">
                            <img :src="item.icon">
                        </div>
                        <div class="text">
                            <div class="name"><span>{{ item.title }}</span></div>
                            <div class="description"><span>{{ item.description }}</span></div>
                            <div class="more"><a @click="toUrl(item.router, item.routerParam)">了解更多></a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
    methods: {
        toUrl(path, param) {
            let params = {}
            if (param) {
                param.split(',').forEach(item => {
                    params[item.split('=')[0]] = item.split('=')[1];
                });
            }
            if (path) {
                this.$router.push({
                    name: path,
                    params
                })
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.title {
    margin: 50px auto;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}

.content {
    max-width: 1200px;
    padding: 50px 20px 20px 20px;
    margin: 0 auto;

    .card {
        display: flex;
        border: 1px solid #cecccc;
        background-color: #ffffff;
        padding: 20px;
        margin-bottom: 20px;
        overflow: hidden;
    }

    .icon {
        margin-right: 14px;
        height: 90px;
    }

    .icon img {
        height: 100%;
    }

    .text {
        flex: 1;
        color: #27244c;
        display: flex;
        flex-direction: column;
    }

    .name {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }

    .description {
        flex: 1;
    }

    .description span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 40px;
        max-height: 40px;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
    }

    .more a {
        cursor: pointer;
        color: #497dcc;
    }

    .more a:hover {
        text-decoration: underline;
    }
}

.content ul {
    width: 100%;
    margin-left: 2%;
}

.content li {
    width: 46%;
    float: left;
    margin-right: 4%;
}

@media screen and (max-width: 700px) {
    .content ul {
        width: 100%;
        margin: 0 auto;
    }

    .content li {
        width: 100%;
    }
}
</style>
