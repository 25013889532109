var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},_vm._l((_vm.data.items),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"item"},[(index % 2 === 0)?_c('div',{staticClass:"image",style:({
                backgroundImage: 'url(' + item.cover + ')',
                backgroundSize: '100% 100%',
            })}):_vm._e(),(_vm.screenwidth === 'large')?_c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"description"},[_vm._v(_vm._s(item.description))]),_c('div',{staticClass:"more"},[_c('a',{on:{"click":function($event){return _vm.toUrl(item.router)}}},[_vm._v("了解更多")])])]):_vm._e(),(index % 2 === 1)?_c('div',{staticClass:"image",style:({
                backgroundImage: 'url(' + item.cover + ')',
                backgroundSize: '100% 100%',
            })},[_c('img',{attrs:{"src":item.icon}})]):_vm._e(),(_vm.screenwidth === 'small')?_c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"description"},[_vm._v(_vm._s(item.description))]),_c('div',{staticClass:"more"},[_c('a',{on:{"click":function($event){return _vm.toUrl(item.router)}}},[_vm._v("了解更多")])])]):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }