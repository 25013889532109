<template>
    <div class="body" :style="{
        backgroundImage: 'url(' + data.items[1].cover + ')',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
                }">
        <div style="font-size: 30px; font-weight: bold;margin-bottom: 100px;">应用场景</div>
        <div class="content" >
            <el-tabs tab-position="left" style="height: 550px;">
                <el-tab-pane
                    v-for="(item, index) in data.items"
                    :key="index"
                    :label="item.title"
                    class="custom-pane">
                    <div class="description">{{ item.description }}</div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  created() {
    console.log('221', this.data);
  }
};
</script>

<style lang="scss" scoped>
.body {
    width: 100%;
    height: 900px;
    text-align: center;
    color: #fff;
    margin: 0 auto;
    // max-width: 2000px;
    padding-top: 30px;
    padding-top: 80px;
}

@media screen and (min-width: 700px) {
    .content {
        display: flex;
        width: 80%;
        max-width: 1200px;
        // text-align: center;
        margin: 0 auto;
    }
}

.description {
    padding-bottom: 12px;
    padding-left: 65px;
    padding-right: 20px;
    white-space: pre-line;
    text-align: left;
    font-size: 16px;
    line-height: 50px;
    color: #fff;
    // line-height: 35px;
}

.image1 {
    height: 270px;
}

/deep/.content .el-tabs {
    width: 100%;
    /* 设置 el-tabs 宽度为100% */
}

/deep/.content .el-tabs .el-tabs__nav {
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.1);
    padding-top: 20px;
    padding-bottom: 30px;
    border-radius: 14px;
    /* 将导航项的排列方向改为垂直列 */
}

/deep/.content .el-tabs .el-tabs__item {
    color: #fff;
    display: flex;
    align-items: center;
    height: auto;
    font-weight: 400;
    font-size: 16px;
    // height: 63px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-left: 3px solid transparent;
}

/deep/.content .el-tabs .el-tabs__item.is-active {
    background-color: transparent;
    /* 取消选中项的背景色 */
    border-left-color: #3C90FF;
    color: #3C90FF;
    /* 设置选中项左边框颜色为蓝色 */
}

/deep/.content .el-tabs .el-tabs__label {
    flex: 1;
    /* 填充剩余空间 */
    padding-left: 15px;
    /* 左边距，根据需要调整 */
    text-align: left;
}

/deep/.content .el-tabs .el-tabs__active-bar {
    display: none;
    /* 隐藏默认的active-bar */
}

/deep/.content .el-tabs .el-tabs__nav-wrap::after {
    content: none;
}

@media screen and (max-width: 700px) {
    .body {
        margin: 0 auto;
        padding-top: 50px;
        height: 60vh;
    }

    .content {
        width: 100%;
    }
    /deep/.content .el-tabs .el-tabs__item {
        display: block;
        width: 45vw;
        font-size:3.2vw;
        margin-top: 0.3vw;
        margin-bottom: 0.3vw;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis;
    }

    .description {
        width: 100%;
        // height: 13vh;
        padding-left: 4vw;
        font-size: 3vw;
        line-height: 4.5vw;
        white-space: pre-line;
    }

    .text {
        width: 94%;
        margin: 0 auto;
    }

    // .description {
    //     font-size: 18px;
    //     line-height: 30px;
    //     color: #666;
    // }
}</style>
