<template>
    <div class="body">
        <ul class="item">
            <li v-for="(item, index) in data.items" :key="index">
                <div class="image"><img :src="item.icon" /></div>
                <div class="title">{{ item.title }}</div>
                <div class="description"> {{ item.description }}</div>
            </li>
            <div class="clear"></div>
        </ul>
    </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  created() {
    console.log(this.data);
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.clear {
    clear: both;
}

.body {
    // width: 100%;
    max-width: 1200px;
    // margin: 50px auto 60px auto;
    margin: 50px auto;
    display: block;
    // word-break: break-all;
}
.item{
    width: 100%;
    margin: 0 auto;
}

.image {
    height: 55px;
    width: 100%;
    margin: 20px auto;
}

.body li {
    width: 23%;
    height: 240px;
    padding: 8px 0;
    float: left;
    margin-top: 10px;
    margin-right: 2%;
    text-align: center;
    border-radius: 14px;
    border: 1px solid #CFCFCF;
}

.body img {
    height: 100%;
    max-width: 100%;
    margin-top: 10px;
}

.title {
    width: 100%;
    font-size: 15px;
    color: #000;
    margin: 30px 0 10px 0;
    padding-left: 10px;
    padding-right: 10px;
    display: -webkit-box;
    font-weight: bold;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.description {
    width: 85%;
    margin: 0 auto;
    font-size: 10px;
    line-height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-align: left;
}

@media screen and (max-width: 700px) {
    .body {
        width: 96%;
        margin: 0 auto;
    }

    .image {
        height: 13vw;
        width: 90%;
        margin: 0 auto;
    }

    .body li {
        padding: 3% 4%;
        width: 45%;
        float: left;
        margin: 10px 2%;
        text-align: center;
    }

    .title {
        font-size: 16px;
        // line-height: 30px;
        // margin: 25px 0 10px 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }

    .description {
        // width: 100%;
        font-size: 13px;
        // line-height: 16px;
        margin-top: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
}
</style>
