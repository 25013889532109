<template>
    <div class="body">
        <ul>
            <li v-for="(item, index) in data.items" :key="index">
                <div class="text">
                    <div class="title">{{ item.title }}</div>
                    <div class="description">{{ item.description }}</div>
                </div>
                <div class="clear"></div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
};
</script>

<style lang="scss" scoped>
.clear {
    clear: both;
}
.body {
    width: 96%;
    margin: 0 auto;
    max-width: 1200px;
    white-space: pre-line;
}

.body ul {
    margin-left: -2%;
    margin-top: 20px;
}

.body li {
    width: 17.8%;
    height: 120px;
    float: left;
    margin-left: 2%;
    background: #fff;
    border: 1px solid #bababb;
    text-align: center;
    border-radius: 6px;
    padding: 22px 0;
    margin-bottom: 24px;
}

.text {
    width: 80%;
    margin: 0 auto;
}

.title {
    font-size: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.description {
    font-size: 16px;
    margin-top: 22px;
    color: #999;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

@media screen and (max-width: 700px) {
    .body ul {
        width: 100%;
    }

    .body li {
        width: 45%;
    float: left;
    margin-left: 5%;
    background: #fff;
    border: 1px solid #bababb;
    text-align: center;
    }

    .title {
        font-size: 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }

    .description {
        font-size: 16px;
    margin-top: 12px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
}</style>
