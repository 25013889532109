<template>
    <div class="body">
        <ul>
            <li v-for="(item, index) in data.items" :key="index">
                <div class="text">
                    <div class="title">{{ item.title }}</div>
                    <div class="icon"></div>
                    <div class="description">{{ item.description }}</div>
                </div>
                <div class="clear"></div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
};
</script>

<style lang="scss" scoped>
.clear {
    clear: both;
}

.body {
    width: 96%;
    margin: 0 auto;
    max-width: 1200px;
    white-space: pre-line;
}

.details {
    width: 80%;
    margin: 20px auto 0 auto;
    font-size: 20px;
    line-height: 40px;
}

.body ul {
    margin-left: -1%;
    margin-top: 50px;
}

.body li {
    background: #f4f7f9;
    background-size: 100% 100%;
    width: 46%;
    height: 250px;
    margin-left: 3%;
    float: left;
    padding: 30px 1%;
    border-radius: 12px;
    border: 1px solid #c4c5c5;
    margin-bottom: 60px;
}

.icon {
    width: 18px;
    height: 3px;
    background: #5096ea;
    margin: 20px 0 36px 0;
}

.line {
    position: absolute;
    width: .2rem;
    top: 50%;
    margin-top: -14px;
    left: 106px;
    height: 28px;
    background: #9fa0a1;
}

.text {
    width: 90%;
    float: right;
    margin-right: 28px;
}

.title {
    font-size: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.description {
    font-size: 16px;
    line-height: 22px;
    margin-top: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}

@media screen and (max-width: 700px) {
    .body ul {
        width: 94%;
        margin: 0 auto;
        padding-top: 20px;
    }

    .body li {
        height: 270px;
        width: 100%;
        padding: 30px 0 44px 0;
        border-radius: 12px;
        border: 1px solid #c4c5c5;
        color: #666;
        margin: 0 0 20px 0;
    }

    .title {
        font-size: 28px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }

    .description {
        font-size: 25px;
        line-height: 35px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
}
</style>
