<template>
    <div class="back">
        <div class="body">
            <div class="content">
                <ul>
                    <li v-for="(item, index) in data.items" :key="index">
                        <div class="card" @click="toUrl(item.router, item.routerParam)">
                            <img :src="item.icon">
                            <span>{{ item.title }}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
    methods: {
        toUrl(path, param) {
            let params = {}
            if (param) {
                param.split(',').forEach(item => {
                    params[item.split('=')[0]] = item.split('=')[1];
                });
            }
            if (path) {
                this.$router.push({
                    name: path,
                    params
                })
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.back {
    // background-color: #f5f7f9;
    color: #27244c;
}

.body {
    max-width: 1200px;
    margin: 0 auto;
    border: none;
    // background-color: #f5f7f9;
    padding: 26px 20px;
}

.title {
    margin: 50px auto 10px auto;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}

.content {
    padding: 40px 0px 20px;

    .card {
        padding: 40px 0;
        display: flex;
        background: #ffffff;
        padding-left: 50px;
        cursor: pointer;
        margin-bottom: 15px;
        overflow: hidden;
        box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
    }

    .card img {
        width: 42px;
        height: 42px;
        margin-right: 30px;
    }

    .card:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .card span {
        flex: 1;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
}

.content ul {
    width: 100%;
    margin-left: 2%;
}

.content li {
    width: 31%;
    float: left;
    margin-right: 2%;
}

@media screen and (max-width: 700px) {
    .content ul {
        width: 100%;
        margin-left: 2%;
    }

    .content li {
        width: 46%;
        margin-right: 4%;
    }

    .content {
        .card span {
            font-size: 16px;
        }

        .card {
            padding: 25px 0;
            padding-left: 20px;
        }
    }
}
</style>
