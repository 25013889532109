import { render, staticRenderFns } from "./columnfive.vue?vue&type=template&id=b0af54ba&scoped=true"
import script from "./columnfive.vue?vue&type=script&lang=js"
export * from "./columnfive.vue?vue&type=script&lang=js"
import style0 from "./columnfive.vue?vue&type=style&index=0&id=b0af54ba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0af54ba",
  null
  
)

export default component.exports