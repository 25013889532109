<template>
    <div class="body">
        <div v-for="(item, index) in data.items" :key="index" class="content">
            <div class="text">
                <div class="title">{{ item.title }}</div>
                <span class="description" v-if="item.description">
                    {{ item.description }}
                </span>
            </div>
            <div class="image">
                <img :src="item.cover" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  created() {
    console.log('textright', this.data);
  }
};
</script>

<style lang="scss" scoped>
.body {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 50px;
}

@media screen and (min-width: 700px) {
    .content {
        display: flex;
        width: 100%;
        margin: 0 0 0 auto;
        align-items: center;
        justify-content: space-around;
    }
}

.image {
    width: 46%;
    margin-top: 110px;
    margin-left:60px
}

.image img {
    width: 100%;
    display: block;
}
.title{
    color: #2B6DC5;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    margin-bottom: 35px;
}

.text {
    width: 44%;
    margin-top: 50px;
    margin-right: 22px;
}

.description {
    padding-bottom: 30px;
    font-size: 16px;
    color: #3F3F3F;
    font-weight: 400;
    line-height: 27px;
    white-space: pre-line;
}

@media screen and (max-width: 700px) {
    .body {
        margin: 0 auto;
        padding-top: 50px;
    }

    .content {
        width: 100%;
    }

    .image {
        margin: 0 auto;
        width: 94%;
        margin-top: 10px;
    }

    .image img {
        width: 100%;
        display: block;
    }

    .text {
        width: 94%;
        margin: 0 auto;
    }

    .description {
        font-size: 16px;
        line-height: 26px;
        color: #666;
    }
}
</style>
