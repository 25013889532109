<template>
    <div class="body">
        <div v-for="(item, index) in data.items" :key="index">
            <div class="image" v-if="item.cover">
                <img :src="item.cover" />
            </div>
            <div class="desc" v-if="item.description">
                {{ item.description }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
};
</script>
<style lang="scss" scoped>
.body {
    height: auto;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    .image {
        width: 78%;
        margin: 0 auto;
        text-align: left;
    }

    .image img {
        width: 100%;
        margin-top: 55px;
    }

    .desc {
        margin: 20px 0;
        font-size: 18px;
    }
}</style>
