<template>
    <div class="body">
        <div v-for="(item, index) in data.items" :key="index">
            <div class="title" v-if="item.title">
                {{ item.title }}
                <div v-if="item.buttons">
                    <div v-for="(button, index) in item.buttons" :key="index">
                        <a @click="toUrl(button.router)" style="color: #497dcc">{{ button.name }}</a>
                    </div>
                </div>
            </div>
            <div class="image" v-if="item.cover">
                <img :src="item.cover" />
            </div>
            <div class="description" v-if="item.description">
                {{ item.description }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
    methods: {
        toUrl(url) {
            if (url.includes('http')) {
                window.open(url);
            } else {
                this.$router.push(url);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.body {
    width: 96%;
    margin: 0 auto;
    padding-top: 50px;
    max-width: 918px;
    white-space: pre-line;
}

.image img {
    width: 100%;
    padding-bottom: 15px;
}

.title {
    font-size: 20px;
    line-height: 35px;
    font-weight: bold;
    display: flex;
}

.title a {
    cursor: pointer;
}

.description {
    padding: 10px 0 30px 0;
    font-size: 16px;
    line-height: 30px;
}
</style>
