<template>
    <div class="back">
        <div class="body">
            <div class="content">
                <ul>
                    <li v-for="(item, index) in data.items" :key="index">
                        <div class="card">
                            <img :src="item.icon">
                            <div class="text">
                                <div class="title">{{ item.title }}</div>
                                <div class="description">
                                    {{ item.description }}
                                </div>
                            </div>
                            <div v-if="item.buttons.length">
                                <div v-for="(button, index) in item.buttons" :key="index" class="contact">
                                    <a :href="button.router">
                                        {{ button.name }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
};
</script>
<style lang="scss" scoped>
.back {
    // background-color: #f5f7f9;
    color: #27244c;
}

.body {
    max-width: 1200px;
    margin: 0 auto;
    border: none;
    // background-color: #f5f7f9;
    padding: 26px 20px;
}

.content {
    padding: 20px 0;

    .card {
        height: 166px;
        padding: 20px;
        display: flex;
        align-items: center;
        background: #ffffff;
        margin-bottom: 15px;
        overflow: hidden;
        box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
    }

    .card img {
        width: 100px;
        height: 100px;
        margin-right: 30px;
    }

    .card:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .text {
        width: 60%;
    }

    .title {
        margin-bottom: 10px;
        line-height: 40px;
        color: #202020;
        font-size: 22px;
        font-weight: 500;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }

    .description {
        font-size: 14px;
        font-weight: 400;
        color: rgba(162, 162, 162, 1);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }

    .contact {
        margin: 0 5px;

        a {
            cursor: pointer;
            color: #0076a8;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}

.content ul {
    width: 100%;
    margin-left: 1%;
}

.content li {
    width: 48%;
    float: left;
    margin-right: 2%;
}

@media screen and (max-width: 700px) {
    .content ul {
        margin-left: 0;
        width: 100%;
    }

    .content li {
        float: none;
        margin: 0 auto;
        width: 96%;
    }

    .content {
        padding-bottom: 0;
        .card img {
            width: 50px;
            height: 50px;
            margin-right: 30px;
        }

        .card {
            height: auto;
            padding: 25px 0;
            padding-left: 20px;
        }

        .text {
            width: 50%;
        }

        .title {
            font-size: 14px;
        }
        .description {
            font-size: 12px;
            display: block;
        }
    }
}
</style>
