<template>
    <div class="body">
        <ul>
            <li v-for="(item, index) in data.items" :key="index">
                <a @click="toUrl(item.router)">
                    <div class="image">
                        <img :src="item.cover" />
                    </div>
                    <div class="text">
                        <div class="title">
                            {{ item.title }}
                        </div>
                        <div class="description">
                            {{ item.description }}
                        </div>
                        <div class="button" v-if="item.buttons">
                            <div v-for="(button, index) in item.buttons" :key="index">
                                <span>{{ button.name }}</span>
                            </div>
                        </div>
                        <div class="button" v-else>
                            <span>查看详情>></span>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
    methods: {
        toUrl(url) {
            if (url.includes('http')) {
                window.open(url);
            } else {
                this.$router.push(url);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.body {
    width: 98%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
}

.body ul {
    font-size: 0;
}

.body li {
    width: 33.333%;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

.body ul li a {
    width: 90%;
    margin: 23px auto;
    display: block;
    overflow: hidden;
    background: #fff;
}

.image {
    position: relative;
    height: auto;
    background-color: #f6f6f6;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 8 / 5;
}

.image img {
    width: 100%;
    height: 100%;
    transform: translateZ(0px);
    transition: transform 0.3s ease 0s;
}

.text {
    width: 100%;
    padding: 30px 5%;
}

.title {
    color: #585858;
    font-size: 16px;
    height: 40px;
    line-height: 20px;
    font-weight: normal;
}

.description {
    color: #969696;
    font-size: 12px;
    height: 40px;
    line-height: 20px;
    overflow: hidden;
    margin: 20px 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.button {
    color: #3c7bbf;
    font-size: 18px;
}

.body ul li a:hover {
    box-shadow: 0 0 15px #ccc;
}

.body ul li a:hover img {
    transform: scale(1.1, 1.1);
    transition: transform 0.6s ease 0s;
}

@media screen and (max-width: 700px) {
    .body ul {
        width: 96%;
        margin: 0 auto;
    }

    .body li {
        width: 100%;
        margin: 0;
    }

    .title {
        line-height: 25px;
        font-size: 20px;
    }

    .description {
        font-size: 14px;
    }
}
</style>
