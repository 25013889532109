<template>
  <div class="body">
    <div class="card" v-for="(item, index) in data.items" :key="index">
      <div class="content">
        <div class="left">
          <img :src="item.icon" />
        </div>
        <div class="right">
          <div class="text">
            <h3>{{ item.title }}</h3>
            <p>{{ item.description }}</p>
          </div>
          <div class="button">
            <a :href="item.buttons[0].router" v-if="item.buttons.length">{{ item.buttons[0].name }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  created() {
    console.log(this.data);
  },
  methods: {
    toUrl(url) {
      if (url.includes('http')) {
        window.open(url);
      } else {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  width: 92%;
  max-width: 1300px;
  margin: auto;

  .card {
    width: 96%;
    height: auto;
    background: #224174;
    color: #fff;
    overflow: hidden;
    padding: 100px 0;
    margin: 30px auto;
    border-radius: 20px;

    .content {
      width: 80%;
      margin: auto;
      display: flex;
    }

    .left {
      width: 20%;
      height: 80px;
      padding-top: 15px;
      text-align: center;
    }

    .left img {
      height: 100%;
      max-width: 100%;
    }

    .right {
      display: flex;
      width: 80%;
      margin-left: 40px;
    }
  }

  .text {
    width: 60%;

    h3 {
      font-size: 38px;
      font-weight: normal;
      color: #fff;
    }

    p {
      font-size: 20px;
      padding: 11px 0;
      color: #fff;
    }
  }

  .button {
    width: 25%;
    margin: 15px auto;

    a {
      cursor: pointer;
      height: 50px;
      padding: 10px 40px;
      background: #fff;
      color: #4577bb;
      font-size: 20px;
      text-align: center;
      border-radius: 30px;
    }
  }
}

@media screen and (max-width: 1220px) {
  .body {
    .card {
      height: auto;
      margin: 20px auto;
      padding: 50px 0;

      .content {
        display: block;
        width: 100%;
      }

      .left {
        width: 20%;
        height: 15vw;
        float: none;
        margin: 10px auto;
      }

      .left img {
        height: 100%;
        max-width: 100%;
      }

      .right {
        display: block;
        width: 100%;
        margin: 0 auto;
        float: none;
        text-align: center;
      }
    }

    .text {
      h3 {
        font-size: 5vw;
      }

      p {
        font-size: 2.5vw;
      }
    }

    .text,
    .button {
      margin: 20px auto;
      float: none;

      a {
        padding: 1vw 5vw;
        font-size: 2.5vw;
      }
    }
  }
}
</style>
