import { render, staticRenderFns } from "./librarysum.vue?vue&type=template&id=08a4b164&scoped=true"
import script from "./librarysum.vue?vue&type=script&lang=js"
export * from "./librarysum.vue?vue&type=script&lang=js"
import style0 from "./librarysum.vue?vue&type=style&index=0&id=08a4b164&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a4b164",
  null
  
)

export default component.exports