import { render, staticRenderFns } from "./columneight.vue?vue&type=template&id=4235b789&scoped=true"
import script from "./columneight.vue?vue&type=script&lang=js"
export * from "./columneight.vue?vue&type=script&lang=js"
import style0 from "./columneight.vue?vue&type=style&index=0&id=4235b789&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4235b789",
  null
  
)

export default component.exports