<template>
    <div class="body">
        <div class="content" v-for="(item, index) in data.items" :key="index">
            <span style="font-weight: bold;font-size: 20px;line-height: 32px;">
                {{index+1}}、{{ item.title }}</span>
            <p style="font-size: 18px;margin-top: 15px;margin-bottom: 15px;color: #666;">{{ item.description }}</p>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  created() {
    console.log('bugFixed', this.data);
  }
};
</script>

<style lang="scss" scoped>
.body {
    width: 96%;
    margin: 0 auto;
    max-width: 918px;
    padding-top: 50px;
    padding-bottom: 50px;

}

@media screen and (min-width: 700px) {
    .content {
        width: 100%;
        margin: 0 0 0 auto;
        line-height: 33px;
    }
}

@media screen and (max-width: 700px) {
    .body {
        margin: 0 auto;
        padding-top: 50px;
    }

}
</style>
