<template>
    <div class="body">
        <ul>
            <li v-for="(item, index) in data.items" :key="index">
                <div class="icon">
                    <img v-if="item.icon" :src="item.icon" />
                </div>
                <div class="line"></div>
                <div class="text">
                    <div class="title">{{ item.title }}</div>
                    <div class="description">{{ item.description }}</div>
                </div>
                <div class="clear"></div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
};
</script>

<style lang="scss" scoped>
.body {
    width: 96%;
    margin: 0 auto;
    max-width: 1200px;
    white-space: pre-line;
}

.body ul {
    margin-left: -3%;
    margin-top: 50px;
}

.body li {
    width: 47%;
    height: 150px;
    float: left;
    margin-left: 3%;
    background: #f4f7f9;
    border-radius: 10px;
    border: 1px solid #b3b3b3;
    padding: 24px 30px;
    margin-bottom: 40px;
    position: relative;
    display: flex;
    align-items: center;
}

.icon {
    width: 100%;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -40px;
}

.icon img {
    width: 86px;
    height: 80px;
}

.line {
    position: absolute;
    width: .2rem;
    top: 50%;
    margin-top: -14px;
    left: 106px;
    height: 28px;
    background: #9fa0a1;
}

.text {
    padding-left: 101px;
    float: left;
}

.title {
    font-size: 18px;
    color: #545555;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.description {
    font-size: 14px;
    line-height: 30px;
    color: #8f9192;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

@media screen and (max-width: 700px) {
    .body ul {
        width: 94%;
        margin: 0 auto;
        padding-top: 20px;
    }

    .body li {
        height: auto;
        width: 100%;
        padding: 20px 0;
        border-radius: 12px;
        border: 1px solid #c4c5c5;
        color: #666;
        margin: 0 0 20px 0;
    }

    .icon img {
        width: 76px;
        height: 68px;
    }

    .line {
        left: 95px;
    }

    .text {
        width: 95%;
        padding-left: 110px;
    }

    .title {
        font-size: 16px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }

    .description {
        font-size: 13px;
        margin-top: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
    }
}</style>
